import '@babel/polyfill'
import 'bootstrap'
import Cookies from "js-cookie";
import Tablesaw from 'tablesaw'
import $, { fn } from 'jquery'
import Vue from 'vue'
import moment from 'moment'
import axios from 'axios' 
import TouchSwipe from 'jquery-touchswipe'
import VueLazyload from 'vue-lazyload'
import wurl from 'wurl' 
import Nav from './js/Nav'
import Lazyload from './js/Lazyload'
import Listing from './js/Listing'
import VenuesListing from './js/VenuesListing'
import { PopulateMap, InitMap } from './js/Map'
import LittleMap from './js/littleMap'

var validator = require('validator');


//import requestProposalForm from './js/requestProposalForm'
//import contactVenueForm from './js/contactVenueForm'

Vue.use(VueLazyload, {
    preLoad: 1.3,
    error: 'https://via.placeholder.com/500?text=Image+not+found',
    observer: true
})

let vueListing
window.vueListing = vueListing;

let viewportWidth = $(window).innerWidth()
let viewportHeight = $(window).innerHeight()

let apiRootEndpoint = process.env.NODE_ENV === 'development' ? 'https://visitnewcastle.dev.webcoda.net.au/' : '/'


// ----------------------------------------------------------------------------------------------------//
// -------------------------------- Google map -------------------------------------------------------//
window.initMap = InitMap
window.PopulateMap = PopulateMap  

/*
item.Fullname = submission.Fullname;
item.Email = submission.Email;
item.ContactNumber = submission.ContactNumber;
item.Eventtype = submission.Eventtype;
item.NumberOfDelegates = submission.NumberOfDelegates;
item.preferredstartdate = submission.preferredstartdate;
item.Preferredenddate = submission.Preferredenddate;
item.Venuestylesetup = submission.Venuestylesetup;
item.Additionalcomments = submission.Additionalcomment;
*/


window.contactVenueFormValidate = function (token) {
    var fullname = $.trim($('#fullName').val());
    var email = $.trim($('#email').val());
    var contactNumber = $.trim($('#contactNumber').val());   
    var eventType = $.trim($('#eventType').val());
    var nrDelegates = $.trim($('#nrDelegates').val());
    var startDate = $.trim($('#startDate').val());
    var endtDate = $.trim($('#endDate').val());
    var venuestyle = $.trim($('#venuestyle').val());
    var comments = $.trim($('#comments').val());


    var isvalid = true;

    var focuson = "";

    if ((validator.isByteLength(venuestyle, {min:3, max: 256} )  === false) || (venuestyle === "Please select")) {
        $("#venuestyle").addClass('is-invalid');
        focuson = "#venuestyle";
        isvalid = false;
    }
    if (validator.isDate(endtDate )  === false) {
        $("#endDate").addClass('is-invalid');
        focuson = "#endDate";
        isvalid = false;
    }
    if (validator.isDate(startDate )  === false) {
        $("#startDate").addClass('is-invalid');
        focuson = "#startDate";
        isvalid = false;
    }
    if (validator.isNumeric(nrDelegates )  === false) {
        $("#nrDelegates").addClass('is-invalid');
        focuson = "#nrDelegates";
        isvalid = false;
    }
    if ((validator.isByteLength(eventType, {min:3, max: 256} )  === false) || (eventType === "Please select")) {
        $("#eventType").addClass('is-invalid');
        focuson = "#eventType";
        isvalid = false;
    }
    if (validator.isEmail(email) === false) {
        $("#email").addClass('is-invalid');
        focuson = "#email";
        isvalid = false;
    }
    if (validator.isByteLength(fullname, {min:3, max: 256} )  === false) {
        $("#fullName").addClass('is-invalid');
        focuson = "#fullName";
        isvalid = false;
    }

    if (isvalid) {
        $('button.g-recaptcha').trigger('click');
    } else {
        $(focuson).focus();
    }

}


window.contactVenueForm = function (token) {
    var fullname = $.trim($('#fullName').val());
    var email = $.trim($('#email').val());
    var contactNumber = $.trim($('#contactNumber').val());   
    var eventType = $.trim($('#eventType').val());
    var nrDelegates = $.trim($('#nrDelegates').val());
    var startDate = $.trim($('#startDate').val());
    var endtDate = $.trim($('#endDate').val());
    var venuestyle = $.trim($('#venuestyle').val());
    var comments = $.trim($('#comments').val());
    var VenueNodeId = $.trim($('#VenueNodeId').val());

        let proposal = new Object();
        proposal.CaptchaResponse = token;
        proposal.Fullname = fullname;
        proposal.Email = email;
        proposal.ContactNumber = contactNumber;
        proposal.Eventtype = eventType;
        proposal.NumberOfDelegates = nrDelegates;
        proposal.preferredstartdate = startDate;
        proposal.Preferredenddate = endtDate;
        proposal.Venuestylesetup = venuestyle;
        proposal.Additionalcomment = comments;
        proposal.VenueNodeId = VenueNodeId;


        let proposalApiEndpoint =  "/api/FormApi/SubmiteVenueContact";
        $.ajax({ 
            type: 'POST',
            contentType: 'application/json; charset=utf-8',
            url: proposalApiEndpoint,
            data: JSON.stringify(proposal),
            dataType: "json", 
            success: function (data) {
                if (data.IsSuccess) {
                    window.location.href = data.Message
                } else {
                    $('#formAlert').html(data.Message).addClass('alert-danger').removeClass('alert-info').removeClass('d-none');
                }
            },
            failure: function () {
               $('#formAlert').html('An error has occured...').addClass('alert-danger').removeClass('alert-info').removeClass('d-none');
            }
        });


}


window.requestProposalFormValidate = function () {
    var firstName = $.trim($('#firstName').val());
    var lastName = $.trim($('#lastName').val());
    var emailAddress = $.trim($('#emailAddress').val());
    var ContactNumber = $.trim($('#contactNumber').val());
    var Organisation = $.trim($('#organisation').val());
    var EventName = $.trim($('#eventName').val());
    var EventType = $.trim($('#eventType').val());
    var NumberofDelegates = $.trim($('#delegates').val());
    var AccompanyingPartnerProgram = $.trim($('input[name="partnerProgram"]:checked').val());
    var PostItinerariesAssistance = $.trim($('input[name="assistance"]:checked').val());
    var PreferredStartDate = $.trim($('#startDate').val());
    var PreferredEndDate = $.trim($('#endDate').val());
    var IncludeBumpInOut = $.trim($('input[name="bump"]:checked').val());
    var VenueSetupStyle = $.trim($('#venuestyle').val());
    var SupportRequestedFor = $.trim($('#supportrequest').val());
    var AlsoInterestedIn =  $.trim($('input[name="freeCopy"]:checked').val());
    var otherText = $.trim($('#otherText').val());
    var AdditionalComments = $.trim($('#comments').val());
    var isvalid = true;
    var focuson = "";
    if ((validator.isByteLength(SupportRequestedFor, {min:2, max: 256} )  === false) || (SupportRequestedFor === "Please select")) {
        $("#supportrequest").addClass('is-invalid');
        focuson = "#supportrequest";
        isvalid = false;
    }
    if ((validator.isByteLength(VenueSetupStyle, {min:3, max: 256} )  === false) || (VenueSetupStyle === "Please select")) {
        $("#venuestyle").addClass('is-invalid');
        focuson = "#venuestyle";
        isvalid = false;
    }
    if ((IncludeBumpInOut !== "Yes") && (IncludeBumpInOut !== "No")) {
        $("#bumpGroup").addClass('is-invalid');
        focuson = "#bumpGroup";
        isvalid = false;
    }
    if (validator.isDate(PreferredEndDate )  === false) {
        $("#endDate").addClass('is-invalid');
        focuson = "#endDate";
        isvalid = false;
    }
    if (validator.isDate(PreferredStartDate )  === false) {
        $("#startDate").addClass('is-invalid');
        focuson = "#startDate";
        isvalid = false;
    }

    if ((PostItinerariesAssistance !== "Yes") && (PostItinerariesAssistance !== "No")) {
        $("#assistanceGroup").addClass('is-invalid');
        focuson = "#assistanceGroup";
        isvalid = false;
    }
    if ((AccompanyingPartnerProgram !== "Yes") && (AccompanyingPartnerProgram !== "No")) {
        $("#partnersGroup").addClass('is-invalid');
        focuson = "#partnersGroup";
        isvalid = false;
    }
    if (validator.isNumeric(NumberofDelegates )  === false) {
        $("#delegates").addClass('is-invalid');
        focuson = "#delegates";
        isvalid = false;
    }
    if ((validator.isByteLength(EventType, {min:3, max: 256} )  === false) || (EventType === "Please select")) {
        $("#eventType").addClass('is-invalid');
        focuson = "#eventType";
        isvalid = false;
    }
    if (validator.isByteLength(EventName, {min:3, max: 256} )  === false) {
        $("#eventName").addClass('is-invalid');
        focuson = "#eventName";
        isvalid = false;
    }
    if (validator.isByteLength(Organisation, {min:3, max: 256} )  === false) {
        $("#organisation").addClass('is-invalid');
        focuson = "#organisation";
        isvalid = false;
    }
    if (validator.isByteLength(ContactNumber, {min:6, max: 20} )  === false) {
        $("#contactNumber").addClass('is-invalid');
        focuson = "#contactNumber";
        isvalid = false;
    }
    if (validator.isEmail(emailAddress) === false) {
        $("#emailAddress").addClass('is-invalid');
        focuson = "#emailAddress";
        isvalid = false;
    }
    if (validator.isByteLength(lastName, {min:3, max: 256} )  === false) {
        $("#lastName").addClass('is-invalid');
        focuson = "#lastName";
        isvalid = false;
    }
    if (validator.isByteLength(firstName, {min:3, max: 256} )  === false) {
        $("#firstName").addClass('is-invalid');
        focuson = "#firstName";
        isvalid = false;
    }
    if (isvalid) {
        $('button.g-recaptcha').trigger('click');
    } else {
        $(focuson).focus();
    }

},
window.requestProposalForm = function (token) {

    var firstName = $.trim($('#firstName').val());
    var lastName = $.trim($('#lastName').val());
    var emailAddress = $.trim($('#emailAddress').val());
    var ContactNumber = $.trim($('#contactNumber').val());
    var Organisation = $.trim($('#organisation').val());
    var EventName = $.trim($('#eventName').val());
    var EventType = $.trim($('#eventType').val());
    var NumberofDelegates = $.trim($('#delegates').val());
    var AccompanyingPartnerProgram = $.trim($('input[name="partnerProgram"]:checked').val());
    var PostItinerariesAssistance = $.trim($('input[name="assistance"]:checked').val());
    var PreferredStartDate = $.trim($('#startDate').val());
    var PreferredEndDate = $.trim($('#endDate').val());
    var IncludeBumpInOut = $.trim($('input[name="bump"]:checked').val());
    var VenueSetupStyle = $.trim($('#venuestyle').val());
    var SupportRequestedFor = $.trim($('#supportrequest').val());
    var AlsoInterestedIn =  $.trim($('input[name="freeCopy"]:checked').val());
    var otherText = $.trim($('#otherText').val());
    var AdditionalComments = $.trim($('#comments').val());

    if (AlsoInterestedIn === "Yes") {
        AlsoInterestedIn = "Free copy of the Newcastle planners guide";
    }

    if ((otherText !== "") && (AlsoInterestedIn !== "")) {
        AlsoInterestedIn = AlsoInterestedIn + ", " + otherText
    }
    else if ((otherText !== "") && (AlsoInterestedIn === "")) {
        AlsoInterestedIn = otherText
    }


        let proposal = new Object();
        proposal.CaptchaResponse = token;
        proposal.FirstName = firstName;
        proposal.LastName = lastName;
        proposal.Email = emailAddress;
        proposal.ContactNumber = ContactNumber;
        proposal.Organisation = Organisation;
        proposal.EventName = EventName;
        proposal.EventType = EventType;
        proposal.NumberofDelegates = NumberofDelegates;
        proposal.AccompanyingPartnerProgram = AccompanyingPartnerProgram;
        proposal.PostItinerariesAssistance = PostItinerariesAssistance;
        proposal.PreferredStartDate = PreferredStartDate;
        proposal.PreferredEndDate = PreferredEndDate;
        proposal.IncludeBumpInOut = IncludeBumpInOut;
        proposal.VenueSetupStyle = VenueSetupStyle;
        proposal.SupportRequestedFor = SupportRequestedFor;
        proposal.AlsoInterestedIn = AlsoInterestedIn;
        proposal.AdditionalComments = AdditionalComments;
        let proposalApiEndpoint =  "/api/FormApi/SubmitProposal";
        console.log('POST SubmitProposal');
        $.ajax({ 
            type: 'POST',
            contentType: 'application/json; charset=utf-8',
            url: proposalApiEndpoint,
            data: JSON.stringify(proposal),
            dataType: "json", 
            success: function (data) {
                if (data.isSuccess) {
                    window.location.href = data.message
                } else {
                    $('#formAlert').html(data.message).addClass('alert-danger').removeClass('alert-info').removeClass('d-none');
                }
            },
            failure: function () {
               $('#formAlert').html('An error has occured...').addClass('alert-danger').removeClass('alert-info').removeClass('d-none');
            }
        });



}

$(() => {
    Tablesaw.init();

    $(document).on("click", ".modal-backdrop.toggle-pagelock-off.show.fade", function() {
        $('body').removeClass('no-scroll').css('overflow','');
        $('html').css('overflow','');
    });

    $('.validation-check').on('keydown', function(){
        $(this).removeClass('is-invalid');
        $('#formAlert').add('d-none');
    });
    $('.validation-check').on('change', function(){
        $(this).removeClass('is-invalid');
        $('#formAlert').add('d-none');
        if ($(this).parent().parent().hasClass('is-invalid')) {
            $(this).parent().parent().removeClass('is-invalid');
        }
    });

    $('#other').on('change', () => {
        if ($('input[name="other"]:checked').val() ) {
            $('#otherText').removeClass('d-none');
        } else {
            $('#otherText').addClass('d-none');
        }
    });
    


    // main nav init
    Nav()

    let isDesktopMenu = false;
    let isDesktop;
    let showsticky = false;
    let showSearchbox = false;
    let isLanding = false;
    let isPageLocked = false;
    let windowWidth;

    let checkViewportSize = function () {
        windowWidth = window.innerWidth;
        isDesktop = (windowWidth >= 992) ? true : false;
    }
    checkViewportSize();

    $('[data-toggle="tooltip"]').tooltip()





    // -------------------------------- ScrollLoc --------------------------------------------------------//
    // Catch button events
    $(".toggle-pagelock").click(function (event) {
        togglePageLock()
    })

    const togglePageLock = function () {
        let isShowMap;
        if ($('#mapPopup.show')[0]) {
            isShowMap = true
        }
        else isShowMap = false

        if (!isPageLocked) {
            $('html, body').css({ "overflow": "hidden" })
            isPageLocked = true;
        }
        else if (!isShowMap) {
            $('html, body').css({ "overflow": "visible" })
            isPageLocked = false;
        }
    }

    // landing page
    if ($('.page-landing')[0]) {
        $('#header').toggleClass('header-landing');
    }

    // Drop-down custom
    $('.drop-down__select').click(function () {
        $(this).toggleClass('drop-down__open');
    })

    // stagger animation on menu items
    $('.dropdown-menu__container').each(function (i) {
        var timeInMs = 0
        $(this).find('.dropdown-menu__item').each(function (index) {
            $(this).css('transition', function () {
                timeInMs += 75
                return `all .25s ease-in-out ${timeInMs}ms`
            })
        })
    }) 

    $('.o-dropdown').each(function() {
        let $dd = $(this)
        
        $dd.find('.o-dropdown-toggle').on("click", function() {
            let $parent = $(this).parent()
            $parent.toggleClass('show')
            $parent.siblings().removeClass('show')
        })
    })

    // ----------------------------------------------------------------------------------------------------//
    // -------------------------------- SearchBox --------------------------------------------------------//

    let toggleSearchBox = function () {
        if (!showSearchbox) {
            let bd = "<div class='modal-backdrop toggle-pagelock-off show fade' data-backdrop='static'></div>"
            $(bd).appendTo(document.body);
            showSearchbox = true;
            setTimeout(() => $('#searchtext').focus(), 100)
            $('.modal-backdrop').click((e) => {
                $('#searchbox').toggleClass('show')
                toggleSearchBox();
            });
        }
        else if (showSearchbox) {
            $(".modal-backdrop").remove();
            showSearchbox = false;
        }
    }
    $('.search-toggle').click((e) => {
        toggleSearchBox();

    })

    // -------------------------------- Go to top BTN-----------------------------------------------------//
    $('.gototop').click((e) => {
        e.preventDefault();

        $("html, body").animate({
            scrollTop: 0
        }, {
            duration: ".8s"
        });
    })

    if ($('audio').length) {
        $('audio').wrap('<div class="ckeditor-html5-audio">');
        var $audio = $(".ckeditor-html5-audio audio");
        $audio.each(function () {
            var el = this;
            var $el = $(el);
            $el.wrap('<div class="richtext-audio u-posr u-curp u-dib"></div>')
                .before('<span class="fa fa-volume-up u-dn-editmode" aria-hidden="true"></span>')
            $el.closest('.richtext-audio').on("click", function () {
                el.play();
            })
        })
    }

    // --------------------------------------- Megamenu --------------------------------------------------//
    let menuItems = $('.nav-item')
    menuItems.hover(
        function () {
            if (isDesktop) {
                let itemIndex = menuItems.index(this)
                if ($(this).has('.dropdown-menu')[0]) {
                    $(this).toggleClass('show')
                    $(this).find('.dropdown-menu').toggleClass('show')
                }
            }
        })

    // Keep Tablet/Mobile Megamenu in container
    // isDesktopMenu = isDesktop ? true : false;
    let toggleTabletMobileMenu = function () {
        if (isDesktop != !isDesktopMenu) {
            $('#mainnav .nav').toggleClass('container')
            isDesktopMenu = !isDesktopMenu;
        }
    }

    // JS resize event
    $(window).resize(function () {
        checkViewportSize();
        viewportWidth = $(window).innerWidth()
        viewportHeight = $(window).innerHeight()
        toggleTabletMobileMenu();
    })

    // -------------------------------- Lazyload image ---------------------------------------------------//
    if ($('.lazy-image')[0]) {
        Lazyload()
    }

    // ----------------------------------------------------------------------------------------------------//
    // -------------------------------- Vue Components----------------------------------------------------//
    // --------------------------------------------------------------------------------------------------//
    Vue.directive('tooltip', function(el, binding){
        $(el).tooltip({
            title: binding.value,
            placement: binding.arg,
            trigger: 'hover'             
        })
})


    //Listing
    if ($('#listing')[0]) {
        Listing(apiRootEndpoint)
    }

    //events
    if ($('#events')[0]) {
        
        window.vueListing = new Vue({
            el: '#events',
            data: {
                catList: [],
                dateList: [
                    { value: 'any', text: 'Any Date' },
                    { value: 'today', text: 'Today' },
                    { value: 'thisweekend', text: 'This Weekend' },
                    { value: 'thisweek', text: 'This Week' },
                    { value: 'nextweek', text: 'Next Week' },
                    { value: 'next30days', text: 'Next 30 Days' }
                ],
                criteria: {
                    date:'any', 
                    daterange: [],
                },
                picker: null,
                // classList: [],
                selectedCat: "All",
                selectedDateRange: "any",
                listing: [],
                maxItemsToDisplayInit: 24,
                maxItemsToDisplay: null,
                additionalItemsToDisplay: 12,
                showLoader: false,
                classificationsList: [],
            },
            methods: {
                getListing() {
                    this.showLoader = true
                    this.maxItemsToDisplay = this.maxItemsToDisplayInit

                    let paramDate = this.selectedDateRange
                    axios.get('/api/listapi/GetEvents', {
                        mode: 'no-cors',
                        params: {
                            dateRange: paramDate
                        }
                    })
                        .then(response => {
                            let newList = response.data.listing;
                            if($("#selectedProductCategory").length > 0) {
                                // Filter out results that are not in the selected category if classification is empty
                                const onlyFilters = JSON.parse($("#selectedProductCategory").html()).previousSelectedCategory.split(';')
                                newList = response.data.listing.filter(item => item.categoriesID && onlyFilters.includes(item.categoriesID))
                            }
                            this.listing = this.sortByDate(newList)
                            this.showLoader = false
                        })
                        .catch(error => {
                            console.log(error);
                            this.showLoader = false
                        })
                },
                getClassifications: async function() {
                    let classifications = await axios.get('/api/listapi/GetClassifications')
                    this.classificationsList = classifications.data
                },
                populateCategories() {
                    if(this.classificationsList.length > 0 && this.listing.length > 0) {
                        let gatherAllCat = ["All"]
                        this.listing.map(item => {
                            let itemCatList = item.categories.split(";")
                            gatherAllCat.push(itemCatList)
                        })

                        // All filter classifications
                        const classifications = this.classificationsList;
                        const defaultFilters = JSON.parse($("#selectedProductCategory").html()).previousSelectedCategory.split(';')

                        this.catList = classifications.filter(c => defaultFilters.includes(c.ProductTypeId)).sort()
                    }
                },
                flatten(arr) {
                    return arr.reduce((acc, val) => acc.concat(val), [])
                },
                loadMoreItems() {
                    this.maxItemsToDisplay = this.maxItemsToDisplay + this.additionalItemsToDisplay
                },
                mobileApply(){
                    this.applyFilter()
                    this.togglePagelock()
                },
                togglePagelock() {
                    $(function () {
                        togglePageLock()
                    })
                },
                dateDropDown(e){
                    let $parent = $(e.target).parent()
                    $parent.toggleClass('show')
                    $parent.siblings().removeClass('show')
                },
                showDatePicker(e){
                    let $picker = $(e.target).closest(".o-dropdown-menu").find(".startEndDate")
                    $picker.addClass('show');
                },
                blur(e) {
                    e.target.blur()
                },
                getItemDates(item) {
                    let startDay = String(new Date(item.startDate).toLocaleDateString('en-GB', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                    }))
                    let start = String(new Date(item.startDate).toLocaleDateString('en-GB', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric'
                    }))
                    let endDay = String(new Date(item.endDate).toLocaleDateString('en-GB', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                    }))
                    if (startDay != endDay) return startDay + " - " + endDay;
                    else return start
                },
                sortByDate(list) {
                    let newList = []
                    list.map(item => {
                        if (item !== null) newList.push(item)
                    })
                    newList.sort((a, b) => {
                        let aStartDate = new Date(a.startDate)
                        let bStartDate = new Date(b.startDate)

                        return aStartDate.getTime() > bStartDate.getTime() ? 1 : -1
                    })
                    return newList
                },
                anytime(){
                    this.criteria.daterange = []
                    $("#FilterDateStart2").val("")
                    $("#FilterDateEnd2").val("")
                    document.getElementById('submit-btn').setAttribute('disabled', true)
                },
                clearAll(){
                    this.anytime()
                    this.picker.clearSelection()
                    $("#selectDateRangeStatic").val("")
                },
                resetFilter(){
                    this.clearAll()
                    $("#FilterDateStart2").closest(".o-dropdown").removeClass("show")
                },
                applyFilter(){
                    let startDate = $("#FilterDateStart2").val()
                    let endDate = $("#FilterDateEnd2").val()
                    startDate = startDate.length? startDate : "01 Jan 00"
                    endDate = endDate.length? endDate: "31 Dec 49"

                    this.criteria.daterange = [startDate, endDate]
                    $("#FilterDateStart2").closest(".o-dropdown").removeClass("show")
                },
            },
            computed: {
                listingByCat: function () {
                    return this.listing.filter(item => {
                        if (this.selectedCat != "All") {
                            if (item.categoriesID.indexOf(this.selectedCat) >= 0) {
                                return item
                            }
                        }
                        else return item
                    })
                },
                filteredListing: function () {
                    let posts = this.listingByCat
                    
                    if(this.criteria.daterange.length){
                        this.criteria.eventStart = [];
                        const flatDateStart = this.criteria.daterange[0].split('+').join("")
                        const flatDateEnd = this.criteria.daterange[1].split('+').join("")
                        let startDate = new Date(flatDateStart).getTime(); 
                        let endDate = new Date(flatDateEnd).getTime();
    
                        let startDateTime = moment(startDate).format('Y-MM-DDT00:00:00');
                        let getStartDate = moment(startDateTime).format('x'); 
                        let endDateTime = moment(endDate).format('Y-MM-DDT24:00:00');
                        let getEndDate = moment(endDateTime).format('x'); 
                        
                        posts =  posts.filter(item => {
                            // console.log(startDate, endDate, startDate <= new Date(item.startDate).getTime(), endDate >= new Date(item.endDate).getTime())
                            return (startDate <= new Date(item.startDate).getTime() && endDate >= new Date(item.endDate).getTime()) 
                        }).sort((a,b) => (a.endDate > b.endDate) ? 1 : -1)    
                    }
                    return posts.slice(0, this.maxItemsToDisplay)
                },
                isMoreItems: function () {
                    return this.listingByCat.length > this.maxItemsToDisplay
                }
            },
            watch: {
                selectedDateRange() {
                    this.getListing()
                },
                listing() {
                    this.populateCategories()
                },
                classificationsList() {
                    this.populateCategories()
                }
            },
            mounted: function () {
                if ($('.startDateRangepicker')[0]) {
                    const picker = this.picker = new window.Litepicker.Litepicker({
                        element: document.getElementById('selectDateRangeStatic'),
                        singleMode: false,
                        inlineMode: true,
                        format: 'D MMM YYYY',
                    })
                    picker.on("preselect", (date1, date2) => {
                        var cd1 = moment(date1.toDateString()).format('DD MMM YY')
                        var cd2 = moment(date2.toDateString()).format('DD MMM YY')
                        //output 21Nov20 --- search format should be 20+Nov+20
                        // console.log(cd1, cd2)
                        document.getElementById('FilterDateStart2').value = cd1
                        document.getElementById('FilterDateEnd2').value = cd2
                        document.getElementById('submit-btn').removeAttribute('disabled')
                    })
                }
                $(".radioDates").each(function(){
                    var $radio = $(this).find("input[type='radio']")
                    var $picker = $(this).find(".startEndDate")

                    $radio.on("change", function(){
                        if($(this).val() !== 'select'){
                            $picker.removeClass('show')
                        }else{
                            $picker.addClass('show')
                        }
                    })
                })
                this.getClassifications()
                this.getListing()
            }
        })
    }

    //BlogListing
    if ($('#blogListing')[0]) {
        new Vue({
            el: "#blogListing",
            data: {
                blogListing: [],
                allFilters: [],
                selectedFilter: "",
                maxItemsToDisplay: 10,
                additionalItemsToDisplay: 6
            },
            
            methods: {
                loadMoreItems() {
                    this.maxItemsToDisplay = this.maxItemsToDisplay + this.additionalItemsToDisplay
                }
            },
            computed: {
                filteredListing: function () {
                    return this.blogListing.filter(a => {
                        if (this.selectedFilter === "") return a
                        else return a.Categories.includes(this.selectedFilter)
                    })
                },
                listingToDisplay: function () {
                    return this.filteredListing.slice(0, this.maxItemsToDisplay)
                },
                isMoreItems: function () {
                    return this.filteredListing.length > this.maxItemsToDisplay
                },
            },
            mounted: function () {
                axios.get("/api/ListApi/GetBlogArticles")
                    .then(res => {
                        this.blogListing = res.data.ResponseObject
                        this.allFilters = res.data.BlogCategories.split(',')
                    })
                    .catch(err => console.log(err)
                    )
            }
        })
    }

    //events
    if ($('#suggestions')[0]) {
        new Vue({
            el: '#suggestions',
            data: {
                suggestionsList: []
            },
            methods: {
                getSuggestions() {
                    const currentProductID = $("#suggestions").data("productid");

                    axios.get('/Api/ListApi/GetRelated',
                            {
                                mode: 'no-cors',
                                params: {
                                    productId: currentProductID
                                }
                            })
                        .then(response => {
                            this.suggestionsList = response.data.products;
                        })
                        .catch(error => {
                            console.log(error);
                        });

                }
            },
            mounted: function () {
                this.getSuggestions()
            },
            computed: {
                suggestedItems: function () {
                    let listLength = this.suggestionsList.length
                    let randIndex = Math.floor(Math.random() * listLength)
                    let limitedIndex = randIndex > listLength - 4 ? listLength - 4 : randIndex
                    let sample = this.suggestionsList.slice(limitedIndex, limitedIndex + 4)
                    return sample
                }
            }

        })

    }

    if ($('#venues')[0]) {
        VenuesListing(apiRootEndpoint) 
    }
    if ($('#littleMap')[0]) {
        LittleMap() 
    }

    if ($('#suppliers')[0]) {
        const dataSuppliers = JSON.parse(document.getElementById('dataSuppliers').innerHTML);
        new Vue({
            el: '#suppliers',
            data: {
                listing: dataSuppliers, 
                maxItemsToDisplay: 12,
                additionalItemsToDisplay: 12, 
                showLoader: false,
                selectedTag:[],
                supplier:'',
                selectedCategory:'all'
            },
            computed:{
                suppliersRemap() { 
                    return this.listing.map(listing => ({
                        ...listing, 
                        image: listing.SupplierImage.replace('~', '')
                    }))
                },
                getCategories(){ 
                    const getType = [...new Map(this.listing.map(item => [item.Type, item])).values()]
                    return getType;
                },
                filteredListing() {
                    let posts = this.suppliersRemap 
                    this.showLoader = true; 

                    if (this.supplier) {
                        posts = posts.filter((p) => {
                            return p.SupplierName.toLowerCase().indexOf(this.supplier) !== -1
                        })
                    }
                    if (this.selectedCategory !== 'all') {
                        posts = posts.filter(c => {
                            return c.Type === this.selectedCategory;
                        })
                    }

                    return posts.slice(0, this.maxItemsToDisplay)
    
                }, 
                isMoreItems: function () {
                    return this.listing.length > this.maxItemsToDisplay
                } 
            },
            methods:{
                loadMoreItems() {
                    this.maxItemsToDisplay = this.maxItemsToDisplay + this.additionalItemsToDisplay
                },
                togglePagelock(e) {
                    $(function () {
                        togglePageLock()
                    })
                },
                blur(e) {
                    e.target.blur()
                },
            }
        })
    }

    if ($('#requestProposalForm')[0]) {
     //   requestProposalForm() 
    }
    if ($('#contactVenueForm')[0]) {
       // contactVenueForm() 
    }


    // Carousel
    $('#carouselWhatson').on('slide.bs.carousel', function (e) {
        var $e = $(e.relatedTarget);
        var idx = $e.index();
        var itemsPerSlide = 4;
        var totalItems = $('#carouselWhatson .carousel-item').length;

        if (idx >= totalItems - (itemsPerSlide - 1)) {
            var it = itemsPerSlide - (totalItems - idx);
            for (var i = 0; i < it; i++) {
                // append slides to end
                if (e.direction == "left") {
                    $('#carouselWhatson .carousel-item').eq(i).appendTo('#carouselWhatson .carousel-inner');
                }
                else {
                    $('#carouselWhatson .carousel-item').eq(0).appendTo('#carouselWhatson .carousel-inner');
                }
            }
        }
    })

    $('#carouselFromBlog').on('slide.bs.carousel', function (e) {
        var $e = $(e.relatedTarget);
        var idx = $e.index();
        var itemsPerSlide = 4;
        var totalItems = $('#carouselFromBlog .carousel-item').length;

        if (idx >= totalItems - (itemsPerSlide - 1)) {
            var it = itemsPerSlide - (totalItems - idx);
            for (var i = 0; i < it; i++) {
                // append slides to end
                if (e.direction == "left") {
                    $('#carouselFromBlog .carousel-item').eq(i).appendTo('#carouselFromBlog .carousel-inner');
                }
                else {
                    $('#carouselFromBlog .carousel-item').eq(0).appendTo('#carouselFromBlog .carousel-inner');
                }
            }
        }
    })

    $('.carousel').each(function () {
        let inner = $(this).find('.carousel-inner');
        let indicator = $(this).find('.carousel-indicators');
        let quantity = $(inner).find('.carousel-item').length;

        if (quantity === 1) {
            $(indicator).hide();
        }
    });

    var carouselSwipe = function () {
        if (viewportWidth < 1280) {
            $('.carousel').swipe({
                swipeLeft: function () {
                    $(this).carousel('next');
                },
                swipeRight: function () {
                    $(this).carousel('prev');
                },
                threshold: 10
            })
        }
    }
    window.onload = carouselSwipe();
    window.onresize = carouselSwipe();

    // social-feed
    if ($('#socialFeed')[0]) {
        new Vue({
            el: '#socialFeed',
            data: {
                isInstaFeed: true,
                isFbFeed: false,
                instaFeed: [],
                fbFeed: [],
                instaName: null,
                fbName: null,
                instaLink: 'https://www.instagram.com/citynewcastle.au/',
                fbLink: null,
                autoplay: false,
                timerPlay: null,
                timer: 5000
            },
            methods: {
                prevSocialItem() {
                    $('#socialFeed .carousel').carousel('prev');
                },
                nextSocialItem() {
                    $('#socialFeed .carousel').carousel('next');
                },
                switchFeed(which) {
                    switch (which) {
                        case "insta":
                            this.isInstaFeed = true;
                            this.isFbFeed = false;
                            break
                        case "fb":
                            this.isInstaFeed = false;
                            this.isFbFeed = true;
                            break
                        default:
                            this.isInstaFeed = true;
                            this.isFbFeed = false;
                    }

                },
                autoplayCarousel() {
                    this.timerPlay = setInterval(() => {
                        this.nextSocialItem()
                    }, this.timer)
                },
                carouselSwipe() {
                    if (viewportWidth < 1280) {
                        const carousel = this.$el.querySelector('.carousel')
                        $(carousel).swipe({
                            swipeLeft: function () {
                                $(this).carousel('next');
                            },
                            swipeRight: function () {
                                $(this).carousel('prev');
                            },
                            threshold: 10,
                            onMouse: true,
                            onTouch: true,
                            options: {
                                //exluded element list, less <a>
                                excludedElements: "button, input, select, textarea, .noSwipe",
                                //trigger <a> on tap
                                tap: function (event, target) {
                                    $(target).parent().trigger('click');
                                }
                            }
                        })
                    }
                }
            },
            mounted() {
                axios.get('https://www.instagram.com/citynewcastle.au/', {
                    params: {
                        __a: 1
                    }
                })
                    .then(response => {
                        this.instaName = response.data.graphql.user.username
                        this.instaFeed = response.data.graphql.user.edge_owner_to_timeline_media.edges
                    })
                    .catch(error => {
                        console.log(error);
                    })


                axios.get('https://graph.facebook.com/v4.0/visitnewcastle', {
                    params: {
                        // fields: 'feed{created_time,message,full_picture,permalink_url,attachments},username,link',
                        fields: 'posts{created_time,message,full_picture,permalink_url,attachments},username',
                        access_token: 'EAAE17CbQjxMBADus0kBJvzkRpMKnY3PVtI1R91Eei44eEEZBFkAs2hlVvYOrc6oD03RXV4442OMtZAXAnMtAtqXiA2SbY5FvKfgoFdtOOCcArN4WwyMEsrHSzMMFm1LQNv4csFk6PGvbtHGMayUZBXtBxvpFLiwbQvr7ZCz5XAZDZD'
                    }
                })
                    .then(response => {
                        this.fbName = response.data.username;

                        this.fbLink = response.data.link;

                        response.data.posts.data.forEach((item, index) => {
                            let srcImage = getImage(item)
                            this.fbFeed.push({
                                "image": item.full_picture || srcImage,
                                "legend": item.message,
                                "url": item.permalink_url
                            })

                            function getImage(item) {
                                let defaultImg = 'https://via.placeholder.com/150?text=Image+not+found'
                                if (item.attachments) {
                                    return item.attachments.data[0].media ? item.attachments.data[0].media.image.src : defaultImg
                                } else {
                                    return defaultImg
                                }
                            }
                        })
                    })
                    .catch(error => {
                        console.log(error);
                    })

                const carousel = this.$el.querySelector('.carousel')
                $(carousel).on('slide.bs.carousel', function (e) {

                    var $e = $(e.relatedTarget);
                    var idx = $e.index();
                    var itemsPerSlide = 7;
                    var totalItems = $('#socialFeed .carousel-item').length;

                    if (idx >= totalItems - (itemsPerSlide - 1)) {
                        var it = itemsPerSlide - (totalItems - idx);
                        for (var i = 0; i < it; i++) {
                            // append slides to end
                            if (e.direction == "left") {
                                $('#socialFeed .carousel-item').eq(i).appendTo('#socialFeed .carousel-inner');
                            }
                            else {
                                $('#socialFeed .carousel-item').eq(0).appendTo('#socialFeed .carousel-inner');
                            }
                        }
                    }
                });

                window.onload = this.carouselSwipe();
                window.onresize = this.carouselSwipe();
            },
            created() {
                if (this.autoplay) this.autoplayCarousel()
            },
            beforeDestroy() {
                if (this.autoplay) clearInterval(this.timerPlay)
            }
        })
    }

    // Validate form by function
    if ($("#subscriptionForm")[0]) {
        window.vueListing = new Vue({
            el: '#subscriptionForm',
            data: {
                isValid: false
            },
            methods: {
                handleValidateForm() {
                    let form = $(this.$el)

                    if (form) {
                        var inputs = $(form).find('input.is-required[type!="submit"]');
                        var fieldPass = 0;

                        if (inputs.length > 0) {
                            $(inputs).each(function (index, input) {
                                var name = $(input).attr('data-name-display') || 'field';
                                var value = $(input).val();
                                var type = $(input).attr('type');

                                var isValid = value.length > 3 ? hideError(input) : showError(input, 'Your ' + name + ' is required!');

                                if (isValid) {
                                    switch (type) {
                                        case 'email':
                                            var regex = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/g;
                                            regex.test(value) ? hideError(input) : showError(input, 'Is not a valid email. Please check again!');
                                            break;
                                        default:
                                        // Do something
                                    }
                                    fieldPass++;
                                }

                                function showError(el, msg) {
                                    if (msg) {
                                        var helper = $(el).next();
                                        $(helper).text(msg);
                                    }
                                    $(el).parent().addClass('error');
                                    return false;
                                }

                                function hideError(el) {
                                    $(el).parent().removeClass('error');
                                    return true;
                                }
                            });

                            if (fieldPass == inputs.length) {
                                this.isValid = true;
                                this.submitForm();
                                return false;
                            } else {
                                this.isValid = false;
                                return false;
                            }
                        }
                    }
                },
                submitForm() {
                    let form = $(this.$el);

                    if (this.isValid) {

                        grecaptcha.execute('6Lc8JtcZAAAAADp7f53ZkO9dQRbW99LeGqOES-OJ', {action: 'submit'}).then(function(token) { 

                            // token returns null for some reason, so implementing
                            // an interval to check for token manually.

                            let retryCount = 0;
                            const interval = setInterval(() => {
                                waitForRecaptchaBeforeSubmit()
                                retryCount++;
                                if(retryCount > 20) clearInterval(interval)
                            }, 500);

                            function waitForRecaptchaBeforeSubmit() {
                                
                                const recaptchaToken = $('[name="g-recaptcha-response"]').val()
                                if(recaptchaToken) {

                                    let subscriber = new Object();
                                    subscriber.Subscriber_name = $('#Newsletter_Name').val();
                                    subscriber.Subscriber_email = $('#Newsletter_EmailAddress').val();
                                    subscriber.CaptchaResponse = recaptchaToken

                                    $.ajax({
                                        type: 'POST',
                                        contentType: 'application/json; charset=utf-8',
                                        url: '/api/Subscription/SubscribeNewsletter',
                                        data: JSON.stringify(subscriber),
                                        dataType: "json",
                                        success: function (data) {
                                            if (data == true) {
                                                form.hide();
                                                $('.subscribe.alert.alert-success').show();
                                            }
                                        },
                                        failure: function () {
                                            $('.subscribe.alert.alert-info').show();
                                        }
                                    });

                                    clearInterval(interval);

                                }
                            }
                            
                        });

                        
                    } else {
                        return false;
                    }
                }
            }
        });
    }

    // Search result
    if ($("#search-page")[0]) {
        new Vue({
            el: '#search-page',
            data: {
                inputVal: '',
                categories: [],
                showLoader: false,
                showError: null
            },
            methods: {
                handleSearch(e) {
                    if (this.inputVal.length > 0) {

                        this.showLoader = true
                        this.categories = []

                        this.maxItemsToDisplay = this.maxItemsToDisplayInit
                        let searchURL = '/api/searchapi/GetSearchResults';
                        let siteName = window.location.pathname.split('/')[1]
                        if(siteName === "businessevents"){
                            searchURL = '/api/searchapi/GetBusinessEventsSearchResults'
                        }
                        
                        axios.get(searchURL, {
                            params: {
                                searchtext: this.inputVal
                            }
                        })
                            .then(response => {
                                this.categories = this.hasResult(response.data.ResponseObject.searchCategories)
                                this.showLoader = false

                                this.showError = this.categories.length > 0 ? false : true
                            })
                            .catch(error => {
                                console.log(error)
                                this.showError = true
                            })
                    }
                },
                hasResult(array) {
                    return array.filter(category => category.searchItems.length > 0)
                },
                getParamFromUrl(param) {
                    let url = new URL(window.location.href)
                    return url.searchParams.get(param)
                },
                removeError(e) {
                    if (e.key !== 'Enter') {
                        this.showError = false
                    }
                },
                getUrlVars() {
                    var vars = {};
                    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
                        vars[key] = value;
                    });
                    return vars;
                }
            },
            mounted() {
                var searchText = wurl('?searchtext');
                if (searchText && searchText.length > 0) {
                    this.inputVal = searchText
                    this.handleSearch()
                }
                
            },
        })
    }

    const $globalAlert = $('#global-alert');
    if ($globalAlert.length) {
        let initialHeight = $globalAlert.children().height();
        const id = $globalAlert.attr('data-id');
        const existingId = Cookies.get('alert_id')
        const $html = $('html');
        if(existingId != id) {
            $globalAlert.show()
            initialHeight = 0;
        }
        $globalAlert.css('height', initialHeight);
        $html.css("--global-alert-height", `${initialHeight}px`);

        const resizeObserver = new ResizeObserver((entries) => {
          for (let entry of entries) {
            var entryBoxHeight = entry.contentRect.height + 24;
            if (!$globalAlert.length) {
              $html.css("--global-alert-height", `${initialHeight}px`);
            }
            if (entryBoxHeight != initialHeight) {
              initialHeight = entryBoxHeight;
              $globalAlert.css("height", initialHeight);
              $html.css("--global-alert-height", `${initialHeight}px`);
            }
          }
        });

        $globalAlert.find('[data-dismiss="alert"]').on('click', () => {
            Cookies.set('alert_id',id)
            $html.css("--global-alert-height", `0px`);
            resizeObserver.disconnect();
        })

        if($globalAlert.length) {
            resizeObserver.observe($globalAlert.children()[0]);
        }
    }

    if($('#subscribeModal')) {

        let shouldOpen = sessionStorage.getItem('subscribeModalClosed');
        if(!shouldOpen) {
            var showAfterDuration = parseInt($('#subscribeModal').data('duration'));
            if(showAfterDuration) {
                showAfterDuration = showAfterDuration * 1000;  // convert to milliseconds
                setTimeout(function(){
                    $('#subscribeModal').modal('show');
                    sessionStorage.setItem('subscribeModalClosed', 'true');
                }, showAfterDuration)
            }
        }  

        $('.js-subscribeSubmit').on('click', function(e){            
            e.preventDefault();

            $(this).closest('form').find('.o-styled-input').removeClass('has-error');

            var parse = true;
            if($('#subscribeFirstname').val().length == 0) {
                $('#subscribeFirstname').closest('.o-styled-input').addClass('has-error');
                parse = false;
            }
            if($('#subscribeLastname').val().length == 0) {
                $('#subscribeLastname').closest('.o-styled-input').addClass('has-error');
                parse = false;
            }
            var email = $('#subscribeEmail').val();
            var regex = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/g;
            if(email.length == 0 || !regex.test(email)) {
                $('#subscribeEmail').closest('.o-styled-input').addClass('has-error');
                parse = false;
            }
            
            if(parse == true) {

                grecaptcha.execute('6Lc8JtcZAAAAADp7f53ZkO9dQRbW99LeGqOES-OJ', {action: 'submit'}).then(function(token) { 

                    // token returns null for some reason, so implementing
                    // an interval to check for token manually.

                    let retryCount = 0;
                    const interval = setInterval(() => {
                        waitForRecaptchaBeforeSubmit()
                        retryCount++;
                        if(retryCount > 20) clearInterval(interval)
                    }, 500);
                

                    function waitForRecaptchaBeforeSubmit() {
                                
                        const recaptchaToken = $('[name="g-recaptcha-response"]').val()
                        if(recaptchaToken) {

                            let data = {};
                            data.Subscriber_name = $('#subscribeFirstname').val() + ' ' + $('#subscribeLastname').val();
                            data.Subscriber_email = $('#subscribeEmail').val();
                            data.Subscriber_Postcode = $('#subscribePostcode').val();
                            data.CaptchaResponse = recaptchaToken

                            $.ajax({
                                type: 'POST',
                                contentType: 'application/json; charset=utf-8',
                                url: '/api/Subscription/SubscribeNewsletter',
                                data: JSON.stringify(data),
                                dataType: "json",
                                success: function (data) {
                                    if (data == true) {
                                        $('#subscribeModal').modal('hide');
                                        $('#subscribeSuccess').addClass('is-active');
                                        setTimeout(function(){
                                            $('#subscribeSuccess').removeClass('is-active');
                                        }, 3000)
                                    }
                                },
                                failure: function () {
                                    console.log('Error submitting form')
                                }
                            });

                            clearInterval(interval);

                        }
                    }

                });

                
            }
        })
    }

})