import Vue from 'vue'
import axios from 'axios'

export default function Listing(apiRootEndpoint) {
    return new Vue({
        el: '#listing',
        data: {
            catList: [],
            classList: [],
            selectedCat: "all",
            selectedClass: "all",
            selectedSorting: "popular",
            listing: [],
            maxItemsToDisplayInit: 12,
            maxItemsToDisplay: null,
            additionalItemsToDisplay: 12,
            showLoader: false,
            isItineraryListing: false,
            categoryClassSet: false,
            classInitialized: false
        },
        methods: {
            getListing() {
                console.log('getListing')
                this.showLoader = true
                this.maxItemsToDisplay = this.maxItemsToDisplayInit

                let paramCat = this.selectedCat == 'all' ? '' : this.selectedCat
                let paramClass = this.selectedClass == 'all' ? '' : this.selectedClass

                let listingApiEndpoint = `${apiRootEndpoint}Api/ListApi/GetProducts`
                if (this.isItineraryListing) {
                    listingApiEndpoint = `${apiRootEndpoint}api/listapi/GetItineraries`
                }

                axios.get(listingApiEndpoint, { // Api/ListApi/GetItineraries

                    mode: 'no-cors',
                    params: {
                        category: paramCat,
                        classification: paramClass
                    }
                })
                    .then(response => {
                        // console.log(`response is: ${response} `, response)
                        let newList = []
                        if (this.isItineraryListing) {
                            response.data.map(item => {
                                if (item !== null) newList.push(item)
                            })
                        } else {
                            response.data.listing.map(item => {
                                if (item !== null) newList.push(item)
                            })
                        }

                        if($("#selectedProductCategory").length > 0 && paramClass.length == 0) {
                            // Filter out results that are not in the selected category if classification is empty
                            const onlyFilters = JSON.parse($("#selectedProductCategory").html()).previousSelectedCategory.split(';')
                            newList = newList.filter(item => item.classifications && onlyFilters.includes(item.classifications))
                        }
                        
                        this.listing = newList
                        this.sortListing()
                        this.showLoader = false
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
            loadMoreItems() {
                this.maxItemsToDisplay = this.maxItemsToDisplay + this.additionalItemsToDisplay;
            },
            togglePagelock(e) {
                $(function () {
                    togglePageLock()
                })
            },
            blur(e) {
                e.target.blur()
            },
            sortListing() {
                if (this.selectedSorting == "popular") {
                    this.listing.sort(function (a, b) { return b.popularity - a.popularity })
                }
                else if (this.selectedSorting == "alpha") {
                    this.listing.sort(function (a, b) { return a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1 })
                }
                PopulateMap(this.listing)
            },
            getUrlParams(search) {
                let hashes = search.slice(search.indexOf('?') + 1).split('&')
                let params = {}
                hashes.forEach(hash => {
                    let [key, val] = hash.split('=')
                    if(key) {
                        params[key] = decodeURIComponent(val)
                    }
                })
            
                return params
            },
            removeTilde(str) {
                let url = str
                if (str.includes('~')) {
                    url = str.replace('~', '')
                }
                return url
            },
            changeUrlParams(params){
                const existingParams = this.getUrlParams(location.search.substring(1));
                const finalParams = existingParams ? {
                    ...existingParams,
                    ...params,
                } : params;
                const urlParamStr = Object.keys(finalParams).filter(key => !!finalParams[key]).map((key) => `${key}=${finalParams[key]}`).join('&')
                history.pushState(null, null, urlParamStr ? `?${urlParamStr}` : location.pathname);
            }
        },
        computed: {
            filteredListing: function () {
                 console.log('this.listing', this.listing)
                return this.listing.slice(0, this.maxItemsToDisplay)
            },
            isMoreItems: function () {
                return this.listing.length > this.maxItemsToDisplay
            },
            hideClassificationFilter: function() {
                return $('#selectedProductCategory').length > 0
            }
        },
        watch: {
            selectedCat() {
                if (!this.categoryClassSet) {
                    this.selectedClass = 'all'
                    this.categoryClassSet = false;
                }
                
                let classificationApiEndpoint = `${apiRootEndpoint}Api/ListApi/GetClassifications`
                if (this.isItineraryListing) {
                    classificationApiEndpoint = `${apiRootEndpoint}api/listapi/GetItinerariesClassifications`
                }

                axios.get(classificationApiEndpoint, { // Api/ListApi/GetItineraries
                    mode: 'no-cors',
                    params: {
                        category: this.selectedCat
                    }
                })
                    .then(response => {
                        let dataList = response?.data?.map(item => ({...item, ProductTypeId: item.ProductTypeId.toUpperCase() }))
                        if($("#selectedProductCategory").length > 0) {
                            const onlyFilters = JSON.parse($("#selectedProductCategory").html()).previousSelectedCategory.split(';')
                            const filterSelectors = dataList.filter(item => onlyFilters.includes(item.ProductTypeId))
                            this.classList = filterSelectors
                        } else {
                            this.classList = dataList
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    })
                this.getListing()
            },
            selectedClass(newVal) {
                this.changeUrlParams({ class: newVal == 'all' ? '' : newVal })
                if (this.classInitialized) {
                    this.classInitialized = false;
                } else {
                    this.getListing();
                }
            },
            selectedSorting() {
                this.sortListing()
            }
        },
        mounted: function () {
            this.isItineraryListing = this.$el.classList.contains('itinerary-listing')

            let catApiEndpoint = `${apiRootEndpoint}Api/ListApi/getcategories`

            if (!this.isItineraryListing) {
                axios.get(catApiEndpoint, { 
                    mode: 'no-cors'
                }).then(response => {
                    
                    this.catList = response.data
                    
                }).catch(error => {
                    console.log(error);
                })
            }

            if (typeof window.selectedCategory !== undefined || typeof window.selectedCategory !== "") this.selectedCat = window.selectedCategory
            else this.getListing()

            // Filter by url
            let search = location.search.substring(1);
            let params = this.getUrlParams(search);

            if (params.class) {
                this.categoryClassSet = true;
                this.classInitialized = true;
                // ProductTypeId / class is uppercas
                this.selectedClass = params.class.toUpperCase();
            }
        }
    })
}